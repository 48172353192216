import { linkResolver } from 'prismic/linkResolver'
import { _FileLink, FrontpageHero, Maybe, PageHero } from 'prismic-types'

import { HeroCarousel } from 'components/hero-carousel/HeroCarousel'
import { HeroCarouselItem } from 'components/hero-carousel/HeroCarouselItem'
import { generateCroppedImageUrls } from 'components/picture/PrismicCroppablePicture'
import { prismicPictureUrl } from 'components/picture/PrismicPicture'
import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture'
import { asText, RichText } from 'components/rich-text/RichText'
import { Video } from 'components/video/Video'

import {
  createFormatArray,
  defaultBreakpoints,
  FormatArray,
  generateImageSizes,
  // heroCarouselImageSizes,
  imageAsCroppableImage,
} from 'utils/cleverCropping'
import { ExcludesFalse } from 'utils/excludesFalse'
import { getFileExt } from 'utils/getFileExt'
import { useEffect, useState } from 'react'

type HeroCarouselContainerProps = Array<PageHero | FrontpageHero> | null

const heroCarouselImageSizes = {
  ...generateImageSizes({
    ratio: 1000 / 1920,
    wide: 2500,
    desktop: 1500,
  }),
  tablet: {
    width: 1200,
    height: 1200,
  },
  mobile: {
    width: 750,
    height: 1200,
  },
}

export function HeroCarouselContainer({
  data,
  currentUid = '',
}: {
  data?: HeroCarouselContainerProps
  currentUid?: Maybe<string>
}) {
  const [secondSection, setSecondSection] = useState<HTMLElement | null>(null)

  //**Select second section html element of the page so we can scroll to it */
  useEffect(() => {
    const secondSection = document.querySelector('section:nth-child(2)')
    setSecondSection(secondSection as HTMLElement)
  }, [])

  if (!data || !Array.isArray(data) || data.length === 0) {
    return null
  }

  return (
    <HeroCarousel
      themes={[]}
      preloadImages={data
        .slice(0, 1)
        .reduce((accumulator: FormatArray, current) => {
          if (!current) {
            return accumulator
          }
          const possiblyCroppableImage = imageAsCroppableImage(current.image)
          let urls: FormatArray = []

          if (possiblyCroppableImage) {
            const formats: Record<
              string,
              {
                wide?: string
                desktop?: string
                tablet?: string
                mobile?: string
                x1?: string
              }
            > = {}

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const extension = getFileExt(current.image.url) ?? 'jpg'

            formats[extension] = generateCroppedImageUrls(
              current.image,
              heroCarouselImageSizes
            )
            urls = createFormatArray({
              formats,
              breakpoints: defaultBreakpoints,
            })
          } else {
            const x1 = prismicPictureUrl({ image: current.image, width: 1800 })
            const x2 = prismicPictureUrl({
              image: current.image,
              width: 1800,
              multiplier: 2,
            })

            urls = [x1?.src, x2?.src]
              .filter(Boolean as unknown as ExcludesFalse)
              .map((item) => ({ srcSet: item }))
          }

          return accumulator.concat(urls)
        }, [])}
    >
      {data.map((item, i) => {
        const itemLink = item.link ? linkResolver(item.link) : undefined
        const linkUid = itemLink?.split('/').pop()

        return (
          <HeroCarouselItem
            key={i}
            title={asText(item.title)}
            image={
              <RegularOrCroppableImage
                image={item.image}
                width={2000}
                componentSizes={heroCarouselImageSizes}
                lazy={i !== 0}
              />
            }
            video={
              item.video ? (
                <Video
                  autoplay
                  addLayout={false}
                  loop={true}
                  isBackgroundVideo={true}
                  src={(item.video as _FileLink).url}
                  poster={
                    item.image
                      ? prismicPictureUrl({ image: item.image, width: 1600 })
                          ?.src
                      : undefined
                  }
                />
              ) : undefined
            }
            ctaVideo={
              item.cta_video ? (item.cta_video as _FileLink).url : undefined
            }
            safariCtaVideo={
              item.safari_cta_video
                ? (item.safari_cta_video as _FileLink).url
                : undefined
            }
            link={currentUid === linkUid ? `#` : itemLink}
            linkText={item.link_text ? asText(item.link_text) : undefined}
            first={i === 0}
            onClick={() => {
              if (secondSection) {
                secondSection.scrollIntoView({ behavior: 'smooth' })
              }
            }}
          >
            <RichText>{item.content}</RichText>
          </HeroCarouselItem>
        )
      })}
    </HeroCarousel>
  )
}
