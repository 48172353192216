import React from 'react';
import { linkResolver } from 'prismic/linkResolver';
import { PrismicLink } from 'prismic/types/link';
import { PrismicRichText } from 'prismic/types/richtext';
import { FrontpageSlicesGreen_Light, PageSlicesGreen_Light } from 'prismic-types';

import { GreenLight } from 'components/green-light/GreenLight';
import { asText, RichText } from 'components/rich-text/RichText';

type Props = {
  title: PrismicRichText;
  intro: PrismicRichText;
  full?: boolean;
  link?: PrismicLink;
  linkText: PrismicRichText;
  linkSecondary?: PrismicLink;
  linkTextSecondary?: PrismicRichText;
  text?: PrismicRichText;
};

export const GreenLightSlice = (props: Props) => {
  const { title, intro, full, link, linkText, linkSecondary, linkTextSecondary, text } =
    props;

  if (!asText(title) || !asText(intro)) {
    return null;
  }

  return (
    <GreenLight
      title={<RichText>{title}</RichText>}
      intro={<RichText>{intro}</RichText>}
      full={full}
      link={link ? linkResolver(link) : undefined}
      linkText={asText(linkText)}
      linkSecondary={linkSecondary ? linkResolver(linkSecondary) : undefined}
      linkTextSecondary={asText(linkTextSecondary)}
      text={text ? <RichText>{text}</RichText> : undefined}
    />
  );
};

export const prismicSliceToGreenLight = (
  s: PageSlicesGreen_Light | FrontpageSlicesGreen_Light
) => {
  const primary = s.variation?.primary;

  if (!primary) {
    return null;
  }

  const title = (primary.title ?? null) as PrismicRichText;
  const intro = (primary.intro ?? null) as PrismicRichText;
  const full = (primary.full ?? null) as boolean;
  const link = (primary.link ?? null) as PrismicLink;
  const linkText = (primary.linkTextFirst ?? null) as PrismicRichText;
  const linkSecondary = (primary.linkSecondary ?? null) as PrismicLink;
  const linkTextSecondary = (primary.linkTextSecondary ?? null) as PrismicRichText;
  const text = (primary.text ?? null) as PrismicRichText;

  if (!title) {
    return null;
  }

  return (
    <GreenLightSlice
      title={title}
      intro={intro}
      full={full}
      link={link}
      linkText={linkText}
      linkSecondary={linkSecondary}
      linkTextSecondary={linkTextSecondary}
      text={text}
    />
  );
};
