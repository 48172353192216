import { contentList } from 'api/contentlist'
import { useRouter } from 'next/router'
import { linkResolver } from 'prismic/linkResolver'
import { PrismicImage } from 'prismic/types/image'
import { PrismicRichText } from 'prismic/types/richtext'
import {
  _Document,
  _Linkable,
  EducationSlices,
  EducationSlicesCall_To_Action,
  FrontpageSlicesContent_List,
  FrontpageSlicesContent_ListDefaultItems,
  NewsSlices,
  NewsSlicesCall_To_Action,
  PageSlices,
  PageSlicesContent_List,
  PageSlicesContent_ListDefaultItems,
  Power_StationSlices,
  Power_StationSlicesCall_To_Action,
} from 'prismic-types'

import { ArticleCard } from 'components/article-card/ArticleCard'
import { ArticleGrid } from 'components/article-grid/ArticleGrid'
import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture'
import { asText, RichText } from 'components/rich-text/RichText'
import { SectionHeader } from 'components/section-header/SectionHeader'

import { generateImageSizes } from 'utils/cleverCropping'
import { ExcludesFalse } from 'utils/excludesFalse'
import { pageTitleFromSlices, titleHeadingTag } from 'utils/pageTitleFromSlices'

type ContentListItem = {
  title?: PrismicRichText
  image?: PrismicImage
  date?: PrismicRichText
  slices?: Array<
    NewsSlices | PageSlices | Power_StationSlices | EducationSlices
  > | null
} & _Linkable &
  _Document

type Props = {
  title: PrismicRichText
  description: PrismicRichText
  image_square?: boolean
  items: Array<ContentListItem>
  isFrontPage?: boolean
}

export const sortContentListItems = (item: ContentListItem) => {
  const title =
    asText(item.title) !== ''
      ? item.title
      : pageTitleFromSlices(item.slices ?? [])
  const backupImage = item.slices?.find(
    (item) =>
      item.__typename === 'NewsSlicesCall_to_action' ||
      item.__typename === 'EducationSlicesCall_to_action' ||
      item.__typename === 'Power_stationSlicesCall_to_action'
  ) as
    | NewsSlicesCall_To_Action
    | NewsSlicesCall_To_Action
    | Power_StationSlicesCall_To_Action
    | EducationSlicesCall_To_Action
    | null
  const image =
    (item.image || backupImage?.variation?.primary?.image) ?? undefined

  return { title, image }
}

export function ContentListSlice({
  title,
  description,
  image_square,
  items,
}: Props) {
  const { query } = useRouter()

  return (
    <ArticleGrid
      header={
        <SectionHeader title={asText(title)} titleEl={titleHeadingTag(title)}>
          <RichText>{description}</RichText>
        </SectionHeader>
      }
    >
      {items.map((item, i) => {
        const { title, image } = sortContentListItems(item)
        const parent = query.parent as string | undefined

        return (
          <ArticleCard
            key={i}
            title={asText(title)}
            date={asText(item.date)}
            link={parent ? linkResolver(item, parent) : linkResolver(item)}
            image={
              image ? (
                <RegularOrCroppableImage
                  image={image}
                  componentSizes={generateImageSizes({
                    ratio: image_square ? 1 : 482 / 562,
                    wide: 562,
                  })}
                />
              ) : undefined
            }
            image_square={image_square}
          />
        )
      })}
    </ArticleGrid>
  )
}

export type ContentList = PageSlicesContent_List | FrontpageSlicesContent_List
export type ContentListItems = Array<
  PageSlicesContent_ListDefaultItems | FrontpageSlicesContent_ListDefaultItems
>
type AugmentedContentList = Awaited<ReturnType<typeof contentList>>

export function prismicSliceToContentList(s: ContentList) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const items = (s.variation?.items ?? [])
    .flatMap(({ item }) => {
      if (!item || !('title' in item)) {
        return null
      }
      return item as ContentListItem
    })
    .filter(Boolean as unknown as ExcludesFalse)

  let dynamicItems: Array<ContentListItem> = []
  try {
    if (s.variation?.primary?.data) {
      const maybeData =
        (JSON.parse(s.variation.primary.data) as AugmentedContentList) ?? []

      dynamicItems = maybeData
        .map((item) => {
          if (!item || !('title' in item.node)) {
            return null
          }
          return item.node
        })
        .filter(Boolean as unknown as ExcludesFalse)
    }
  } catch (e) {
    console.error('Error parsing dynamic contentlist data', e)
  }

  // comparing `_meta.id` instead of whole `item` to avoid issues when/if GQL query changes
  const itemsDeduped = items.concat(
    dynamicItems.filter((dynamicItem) =>
      items.findIndex((item) => item._meta.id === dynamicItem._meta.id)
    )
  )

  return (
    <ContentListSlice
      title={s.variation?.primary?.title}
      description={s.variation?.primary?.description}
      image_square={s.variation?.primary?.image_square ? true : false}
      items={itemsDeduped}
    />
  )
}
