import { PrismicRichText } from 'prismic/types/richtext'
import { FrontpageHero, Maybe, PageHero } from 'prismic-types'

import { ArticleSection } from 'components/article-section/ArticleSection'
import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout'
import { H1 } from 'components/heading/Heading'
import { asText } from 'components/rich-text/RichText'
import { HeroCarouselContainer } from 'containers/slices/HeroCarouselContainer'

type Props = {
  title?: PrismicRichText
  seo_title?: PrismicRichText
  hero?: Array<PageHero | FrontpageHero> | null
  render_as_new_hero?: boolean
  currentUid?: Maybe<string> | undefined
}

export const PageContainer = ({
  title,
  seo_title,
  hero,
  render_as_new_hero = false,
  currentUid = '',
}: Props) => {
  const renderHero =
    (hero?.filter((item) => item.title || item.image) ?? []).length > 0

  return (
    <>
      {!renderHero && asText(title) && (
        <ArticleSection>
          <GridLayout>
            <GridLayoutItem
              columns={{ tablet: 7, desktop: 8 }}
              offset={{ desktop: 2 }}
            >
              <H1>{asText(title ?? seo_title)}</H1>
            </GridLayoutItem>
          </GridLayout>
        </ArticleSection>
      )}

      {renderHero && (
        <HeroCarouselContainer data={hero} currentUid={currentUid} />
      )}
    </>
  )
}
