/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react'
import Bolt from 'assets/svg/bolt.svg'
import classnames from 'classnames/bind'

import { Link } from 'components/link/Link'

import s from './GreenLight.module.scss'

const c = classnames.bind(s)

type Props = {
  title: React.ReactElement
  intro: React.ReactElement
  full?: boolean
  link?: string
  linkText: string
  linkSecondary?: string
  linkTextSecondary?: string
  text?: React.ReactElement
}

export const GreenLight = (props: Props) => {
  const {
    title,
    intro,
    full,
    link,
    linkText,
    linkSecondary,
    linkTextSecondary,
    text,
  } = props
  const [greenLightSwitch, setGreenLightSwitch] = useState(false)
  const scrollRef = useRef<HTMLHeadingElement>(null)

  const handleToggle = () => {
    setGreenLightSwitch(!greenLightSwitch)
    !greenLightSwitch &&
      scrollRef.current?.scrollIntoView({
        behavior: 'smooth',
        // Doesn't scroll all the way up to element when set to start due to fixed header, fix later if needed
        block: 'start',
      })
  }

  return (
    <div
      className={c(s.greenLight, { greenLightSwitch, full })}
      ref={scrollRef}
    >
      <div className={s.greenLight__inner}>
        <div className={s.greenLight__title}>{title}</div>

        <div className={s.greenLight__toggleButtonWrap} onClick={handleToggle}>
          <button className={s.greenLight__toggleButton}>
            <div className={s.greenLight__iconCircleWrap}>
              <Bolt className={s.greenLight__icon} />
            </div>
          </button>
        </div>

        <div className={s.greenLight__intro}>{intro}</div>

        <div className={s.greenLight__roundedButtonsContainer}>
          {link && linkText && (
            <Link className={s.greenLight__roundedButton} to={link}>
              {linkText}
            </Link>
          )}
          {linkSecondary && linkTextSecondary && (
            <Link className={s.greenLight__roundedButton} to={linkSecondary}>
              {linkTextSecondary}
            </Link>
          )}
        </div>

        {full && text && <div className={s.greenLight__text}>{text}</div>}
      </div>
    </div>
  )
}
